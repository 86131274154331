import React from "react";

const NotFound: React.FC = () => {
  return (
    <div>
      404
    </div>
  )
}

export default NotFound